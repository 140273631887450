<template>
    <div class="admin-page container-xl">
        <div class="row">
            <div class="col-md-6 mb-3">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Requests</h3>
                        <div class="card-options">
                            <button @click="refreshRequests" class="btn btn-primary btn-icon"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-refresh"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Singer</th>
                                <th>Artist / Song</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="request in requests" :key="request.requestId">
                                <td>{{ request.singer }}</td>
                                <td>
                                    {{ request.artist }}
                                    <br />
                                    <small>{{ request.song }}</small>
                                </td>
                                <td>
                                    <button @click="addToQueue(request.requestId)" class="btn btn-primary btn-sm">Add to Queue</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">Queue</h3>
                        <div class="card-options">
                            <button @click="refreshQueue" class="btn btn-primary btn-icon"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-refresh"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" /><path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" /></svg></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <table class="table">
                            <thead>
                            <tr>
                                <th>Singer</th>
                                <th>Artist / Song</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="queueItem in queue" :key="queueItem.queueId">
                                <td>{{ queueItem.singer }}</td>
                                <td>
                                    {{ queueItem.artist }}
                                    <br />
                                    <small>{{ queueItem.song }}</small>
                                </td>
                                <td>
                                    <button @click="updateQueueOrder(queueItem.queueId, 'up')" class="btn btn-primary btn-icon" :class="{ 'btn-disabled': movingItem }">
                                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-caret-up"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 14l-6 -6l-6 6h12" /></svg>
                                    </button>
                                    <button @click="updateQueueOrder(queueItem.queueId, 'down')" class="btn btn-primary btn-icon mx-2" :class="{ 'btn-disabled': movingItem }">
                                        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-caret-down"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6 10l6 6l6 -6h-12" /></svg>
                                    </button>
                                    <button @click="setRemoveFromQueue(queueItem.queueId)" :class="{ 'btn-disabled': movingItem }" class="btn btn-danger btn-icon mx-4" data-bs-toggle="modal" data-bs-target="#removeModal"><svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-trash"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 7l16 0" /><path d="M10 11l0 6" /><path d="M14 11l0 6" /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg></button>
                                    <!-- @click="removeFromQueue(queueItem.queueId)" -->
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-blur fade" id="removeModal" style="display: none;" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm Removal</h5>
                    <button type="button" class="btn-close"  data-bs-toggle="modal" data-bs-target="#removeModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Are you sure you want to remove this item from the queue?
                </div>
                <div class="modal-footer">
                    <button type="button" ref="closeBtn"  data-bs-toggle="modal" data-bs-target="#removeModal" class="btn me-auto" >Close</button>
                    <button type="button" class="btn btn-danger" @click="removeFromQueueConfirmed">Remove</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "AdminPage",
    data() {
        return {
            requests: [],
            queue: [],
            itemToRemove: null,
            movingItem: false,
        };
    },
    created() {
        this.checkAuthAndFetchData();
        console.log("admin page")
    },
    methods: {
        setRemoveFromQueue(id) {
          this.itemToRemove = id;
        },
        removeFromQueueConfirmed() {
            this.removeFromQueue(this.itemToRemove)
        },
        async checkAuthAndFetchData() {
            const token = localStorage.getItem("karaoke-auth");
            if (token) {
                try {
                    const payload = JSON.parse(atob(token.split(".")[1]));
                    const isExpired = payload.exp * 1000 < Date.now();
                    if (isExpired) {
                        this.$router.push("/admin/login");
                    } else {
                        await this.fetchRequests(token);
                        await this.fetchQueue(token);
                    }
                } catch (error) {
                    console.error("Token validation error:", error);
                    this.$router.push("/admin/login");
                }
            } else {
                this.$router.push("/admin/login");
            }
        },
        async fetchRequests(token) {
            try {
                const response = await fetch("https://karaoke-api.bouge.workers.dev/requests", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                this.requests = data.requests;
            } catch (error) {
                console.error("Failed to fetch requests:", error);
            }
        },
        async fetchQueue(token) {
            try {
                const response = await fetch("https://karaoke-api.bouge.workers.dev/queue", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                this.queue = data.queue;
            } catch (error) {
                console.error("Failed to fetch queue:", error);
            }
        },
        async addToQueue(requestId) {
            const token = localStorage.getItem("karaoke-auth");
            try {
                const response = await fetch("https://karaoke-api.bouge.workers.dev/addToQueue", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ requestId }),
                });

                if (response.ok) {
                    await this.fetchQueue(token);
                    await this.fetchRequests(token);
                } else {
                    console.error("Failed to add to queue");
                }
            } catch (error) {
                console.error("Failed to add to queue:", error);
            }
        },
        async removeFromQueue(queueId) {
            const token = localStorage.getItem("karaoke-auth");
            try {
                const response = await fetch("https://karaoke-api.bouge.workers.dev/removeFromQueue", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ queueId }),
                });

                if (response.ok) {
                    await this.fetchQueue(token);
                    await this.fetchRequests(token);
                    this.$refs.closeBtn.click();
                } else {
                    console.error("Failed to remove from queue");
                }
            } catch (error) {
                console.error("Failed to remove from queue:", error);
            }
        },
        async refreshRequests() {
            const token = localStorage.getItem("karaoke-auth");
            await this.fetchRequests(token);
        },
        async refreshQueue() {
            const token = localStorage.getItem("karaoke-auth");
            await this.fetchQueue(token);
        },
        async updateQueueOrder(queueId, direction) {
            this.movingItem = true;
            const token = localStorage.getItem("karaoke-auth");
            try {
                const response = await fetch("https://karaoke-api.bouge.workers.dev/updateInQueue", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ queueId, direction }),
                });

                if (response.ok) {
                    await this.fetchQueue(token);
                    this.movingItem = false;
                } else {
                    console.error("Failed to update queue order");
                    this.movingItem = false;
                }
            } catch (error) {
                console.error("Failed to update queue order", error);
                this.movingItem = false;
            }
        },
    },
};
</script>

<style scoped>
.admin-page {
    padding: 20px;
}

.card {
    margin-bottom: 20px;
}

.table thead th {
    vertical-align: middle;
}

@media (min-width: 768px) {
    .row {
        display: flex;
        justify-content: space-between;
    }
    .col-md-6 {
        flex: 0 0 48%;
    }
}
</style>
