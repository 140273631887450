<template>
  <div class="row">
    <div class="col-12">
      <div class="card mt-4">
        <div class="card-header">
          <div class="card-title">
            Most Popular Songs
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Artist</th>
              <th>Title</th>
            </tr>
            </thead>
            <tbody>
            <tr class="cursor-pointer" @click="selectSong(song.songId)" v-for="song in songs" :key="song.songId">
              <td>{{ song.Artist }}</td>
              <td>{{ song.Title }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      songs: [],
    }
  },
  created() {
    this.fetchSongDetails();
  },
  methods: {
    fetchSongDetails() {
      fetch(`https://karaoke-api.bouge.workers.dev/songs/mostPopular`)
          .then(response => response.json())
          .then(data => {
            this.songs = data.songs;
          })
          .catch(error => {
            console.error('Error fetching song details:', error);
            this.error = 'Error loading song details.';
          });
    },
    selectSong(songId) {
      this.$router.push({path: `/song/${songId}`});
    }
  },

}
</script>