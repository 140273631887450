<template>
  <div>
    <div v-if="showSuccessMessage" class="alert alert-success mb-3" role="alert">
      Song successfully requested!
    </div>
    <h1>Song Search</h1>
    <form @submit.prevent="searchSongs">
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="artist" class="form-label">Artist:</label>
          <input
              type="text"
              id="artist"
              v-model="artist"
              class="form-control"
              placeholder="Enter artist name"
          />
        </div>
        <div class="col-md-6">
          <label for="title" class="form-label">Title:</label>
          <input
              type="text"
              id="title"
              v-model="title"
              class="form-control"
              placeholder="Enter song title"
          />
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button type="submit" class="btn btn-primary">Search Songs</button>
      </div>
    </form>

    <div id="results" class="mt-4">
      <div class="card" v-if="songs.length">
        <div class="card-header">
          <h3 class="card-title">Song Results</h3>
        </div>
        <div class="card-body border-bottom py-3 d-none d-md-block">
          <div class="d-flex">
            <div class="text-secondary">
              Show
              <div class="mx-2 d-inline-block">
                <input type="text" class="form-control form-control-sm" v-model="pageSize" size="3"
                       aria-label="Results count">
              </div>
              entries
            </div>
            <div class="ms-auto text-secondary">
              Search:
              <div class="ms-2 d-inline-block">
                <input type="text" class="form-control form-control-sm" v-model="searchQuery" aria-label="Search song">
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table card-table table-striped table-vcenter text-nowrap datatable">
            <thead>
            <tr>
              <th>Artist</th>
              <th>Title</th>
            </tr>
            </thead>
            <tbody>
            <tr class="cursor-pointer" v-on:click="goToSong(song.songId)" v-for="(song, index) in paginatedSongs" :key="index">
              <td>{{ song.Artist }}</td>
              <td>{{ song.Title }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer d-flex align-items-center">
          <p class="m-0 text-secondary">Showing <span>{{ currentPage * pageSize + 1 }}</span> to
            <span>{{ (currentPage + 1) * pageSize }}</span> of <span>{{ filteredSongs.length }}</span> entries</p>
          <ul class="pagination m-0 ms-auto">
            <li class="page-item" :class="{ disabled: currentPage === 0 }">
              <a class="page-link" href="#" @click.prevent="prevPage">
                <!-- Download SVG icon from http://tabler-icons.io/i/chevron-left -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 6l-6 6l6 6"></path>
                </svg>
                prev
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page - 1 }">
              <a class="page-link" href="#" @click.prevent="goToPage(page - 1)">{{ page }}</a>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages - 1 }">
              <a class="page-link" href="#" @click.prevent="nextPage">
                next
                <!-- Download SVG icon from http://tabler-icons.io/i/chevron-right -->
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 6l6 6l-6 6"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <p v-if="noResults">No results found.</p>
      <p v-if="error">{{ error }}</p>
    </div>
    <hr class="hr"/>
    <h2>Need some inspiration?</h2>
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <a class="btn btn-secondary w-100" href="/top-songs">Top Songs</a>
        <p class="mt-2">
          <i>A Selection of absolute fan favourites</i>
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <button class="btn btn-secondary w-100"  @click="cockySong">I'm Feeling Cocky</button>
        <p class="mt-2">
          <i>Show us what you're made of!</i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      artist: "",
      title: "",
      songs: [],
      noResults: false,
      error: "",
      pageSize: 50,
      currentPage: 0,
      searchQuery: "",
      showSuccessMessage: false
    };
  },
  computed: {
    filteredSongs() {
      if (!this.searchQuery) {
        return this.songs;
      }
      return this.songs.filter(song =>
          song.Artist.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          song.Title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
    paginatedSongs() {
      const start = this.currentPage * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredSongs.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredSongs.length / this.pageSize);
    }
  },
  mounted() {
    this.checkQueryParams();
  },
  methods: {
    checkQueryParams() {
      if (this.$route.query.requested === 'success') {
        this.showSuccessMessage = true;
        this.$router.replace({query: null});
      }
    },
    cockySong() {
      const songs = [273,47049,49758,42164,13386,30026,4962,29589,26193,6835]
      const randomIndex = Math.floor(Math.random() * songs.length);
      this.$router.push({path: `/song/${songs[randomIndex]}`});
    },
    searchSongs() {
      const searchData = {
        artist: this.artist,
        title: this.title
      };

      fetch('https://karaoke-api.bouge.workers.dev/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(searchData)
      })
          .then(response => response.json())
          .then(data => {
            this.songs = data.songs || [];
            this.noResults = this.songs.length === 0;
            this.error = "";
            this.currentPage = 0; // Reset to first page on new search
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.error = "Error loading results.";
          });
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToSong(trackId) {
      this.$router.push({path: `/song/${trackId}`});
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
