<template>
    <div class="page">
        <div class="container-xl">
            <div class="row justify-content-center">
                <div class="col-md-6 col-lg-4">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Admin Login</h3>
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="handleLogin">
                                <div class="mb-3">
                                    <label class="form-label">Password</label>
                                    <input
                                            v-model="password"
                                            type="password"
                                            class="form-control"
                                            required
                                    />
                                </div>
                                <div class="form-footer">
                                    <button type="submit" class="btn btn-primary w-100">Login</button>
                                </div>
                                <div v-if="errorMessage" class="alert alert-danger mt-3">
                                    {{ errorMessage }}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

export default {
    name: "LoginPage",
    setup() {
        const router = useRouter();
        const password = ref("");
        const errorMessage = ref("");

        onMounted(() => {
            const token = localStorage.getItem("karaoke-auth");
            if (token) {
                // Validate the token
                try {
                    const payload = JSON.parse(atob(token.split(".")[1]));
                    const isExpired = payload.exp * 1000 < Date.now();
                    if (!isExpired) {
                        router.push("/admin");
                    }
                } catch (error) {
                    console.error("Token validation error:", error);
                }
            }
        });

        const handleLogin = async () => {
            try {
                const response = await fetch("https://karaoke-api.bouge.workers.dev/login", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ password: password.value }),
                });

                if (!response.ok) {
                    throw new Error("Login failed");
                }

                const data = await response.json();
                localStorage.setItem("karaoke-auth", data.token);
                router.push("/admin");
            } catch (error) {
                errorMessage.value = error.message;
            }
        };

        return {
            password,
            errorMessage,
            handleLogin,
        };
    },
};
</script>

<style scoped>
.page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.card {
    width: 100%;
    max-width: 400px;
}

.card-header {
    text-align: center;
}
</style>
