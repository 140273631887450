import { createRouter, createWebHistory } from 'vue-router';

import HomePage from "@/pages/HomePage.vue"
import SongPage from "@/pages/SongPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import AdminPage from "@/pages/AdminPage.vue";
import TopPage from "@/pages/TopPage.vue";

const routes = [
    { path: '/', component: HomePage, name: "EMF Karaoke"},
    { path: '/song/:songId', component: SongPage, name: "Request Song", props: true},
    { path: '/admin/login', component: LoginPage, name: "Admin Login"},
    { path: '/top-songs', component: TopPage, name: "Top Songs"},
    { path: '/admin', component: AdminPage, name: "Karaoke Admin"}
]



const router = createRouter({
    history: createWebHistory(),
    routes
});
router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});
export default router;