<template>
  <div>
    <h1>Request Song</h1>
    <div v-if="song">
      <h2>{{ song.Artist }} - {{ song.Title }}</h2>
      <div v-if="song.requestCount > 0" class="alert alert-cyan">
        This song has been requested {{ song.requestCount }} time(s) tonight
      </div>
      <form @submit.prevent="executeCaptcha">
        <div class="mb-3">
          <label for="singerName" class="form-label">Singer Name:</label>
          <input
              type="text"
              id="singerName"
              v-model="singerName"
              class="form-control"
              placeholder="Enter singer name"
              required
          />
          <i style="font-size: 14px">Please remember EMF is family friendly and this name may be displayed</i>
        </div>
        <div class="mb-3">
          <!--<label class="form-label">Is it a duet?</label>
          <div>
              <label class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" v-model="duet">
                  <span class="form-check-label">Duet</span>
              </label>
          </div> -->
        </div>
        <div class="mb-3">
          <label class="form-label">Vocal effects?
            <div data-bs-toggle="modal" data-bs-target="#infoModal" style="margin-left: 10px" class="btn btn-ghost-azure btn-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                   stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                   class="icon icon-tabler icons-tabler-outline icon-tabler-info-circle">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"/>
                <path d="M12 9h.01"/>
                <path d="M11 12h1v4h1"/>
              </svg>
            </div>
          </label>
          <label class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="autoTune">
            <span class="form-check-label">Auto Tune</span>
          </label>
        </div>
        <div class="mb-3">
          <label class="form-check form-switch">
            <input class="form-check-input" type="checkbox" v-model="reverb">
            <span class="form-check-label">Reverb</span>
          </label>
        </div>
        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-primary">Request Song</button>
        </div>
      </form>
      <div class="challenge-container" id="challenge-container"></div>
    </div>
    <div v-else>
      <p>Loading song details...</p>
    </div>
    <p v-if="error">{{ error }}</p>
  </div>
  <div class="modal modal-blur fade" id="infoModal" style="display: none;" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Vocal Effects Information</h5>
          <button type="button" class="btn-close"  data-bs-toggle="modal" data-bs-target="#infoModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          We have very basic audio effects which includes realtime reverb and limited real-time pitch correction - please have realistic expectations regarding efficacy of the "AutoTune" however this was a highly requested feature at last EMF.
        </div>
        <div class="modal-footer">
          <button type="button" ref="closeBtn"  data-bs-toggle="modal" data-bs-target="#infoModal" class="btn me-auto" >Close</button>
          <!--<button type="button" class="btn btn-danger">OK</button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['songId'],
  data() {
    return {
      song: null,
      singerName: '',
      duet: false,
      autoTune: false,
      reverb: false,
      error: '',
      captchaToken: '',
      widgetId: null
    };
  },
  created() {
    this.fetchSongDetails();
  },
  methods: {
    fetchSongDetails() {
      fetch(`https://karaoke-api.bouge.workers.dev/song/${this.songId}`)
          .then(response => response.json())
          .then(data => {
            this.song = data.song;
          })
          .catch(error => {
            console.error('Error fetching song details:', error);
            this.error = 'Error loading song details.';
          });
    },
    requestSong() {

      //window.hcaptcha.execute();

      if (!this.captchaToken) {
        this.error = 'Captcha not verified';
        return;
      }

      const requestData = {
        songId: this.songId,
        singerName: this.singerName,
        duet: this.duet === 'yes',
        autoTune: this.autoTune,
        reverb: this.reverb,
        captchaToken: this.captchaToken
      };

      fetch('https://karaoke-api.bouge.workers.dev/requestSong', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              //alert('Song requested successfully!');
              this.$router.push({path: '/', query: {requested: 'success'}});
            } else {
              this.error = 'Error requesting song.';
            }
          })
          .catch(error => {
            console.error('Error requesting song:', error);
            this.error = 'Error requesting song.';
          });
    },
    executeCaptcha() {
      this.widgetId = window.hcaptcha.render('challenge-container', {
        sitekey: '3378233c-4907-44d3-b756-8225d96fa067',
        theme: 'dark', size: 'invisible', 'data-callback': dataCallback
      })
      window.hcaptcha.execute(this.widgetId, {async: true})
          .then(({response, key}) => {
            console.log(response, key);
            this.setCaptchaToken(response)
          })
          .catch(err => {
            console.error(err);
          });
    },
    setCaptchaToken(token) {
      this.captchaToken = token;
      this.requestSong();
    }
  }
};

function dataCallback(token) {
  console.log("TOKEN")
  console.log(token)
}
</script>

<style scoped>
/* Additional styles if needed */
</style>
