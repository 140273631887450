<template>
    <div class="page">
        <header class="navbar navbar-expand-sm navbar-light d-print-none">
            <div class="container-xl">
                <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                    <a href="/">
                        <img src="/logo.png" width="110" height="32" alt="Karaoke" class="navbar-brand-image" />
                    </a>
                </h1>

                <div class="navbar-nav flex-row order-md-last">
                    <div class="nav-item">
                        <button v-on:click="toggleTheme()" class="nav-link d-flex lh-1 text-reset p-0">
                            <span class="avatar avatar-sm">
                                <svg v-if="currentTheme === 'dark'"  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-sun"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" /><path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7" /></svg>
                                <svg v-if="currentTheme === 'light'"  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-moon"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" /></svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
        <div class="page-wrapper">
            <div class="page-body my-2">
                <div class="container-xl">
                <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            currentTheme: this.getStoredTheme() || this.getInitialTheme()
        }
    },
    methods: {
        getInitialTheme() {
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        },
        getStoredTheme() {
            return localStorage.getItem('theme');
        },
        toggleTheme() {
            this.currentTheme = this.currentTheme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', this.currentTheme);
            document.documentElement.setAttribute('data-bs-theme', this.currentTheme);
        },
    },
    mounted() {
        document.documentElement.setAttribute('data-bs-theme', this.currentTheme);
    }
}
</script>

<style>


</style>